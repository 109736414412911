import React from 'react';
import { useAudio } from '../../../hooks/useAudio';
import { audioIntroRoom } from '../../../assets/audio';
import { useGameActions } from '../../../hooks/useGameActions';
import { Subtitle } from '../Subtitle/Subtitle';

export const RoomIntroScene: React.FC = () => {
  const { nextScene, startGameTime } = useGameActions();
  const { subtitle } = useAudio(audioIntroRoom, {
    onComplete: () => nextScene(true, false),
    onStart: () => window.setTimeout(startGameTime, 17000),
  });

  return <Subtitle message={subtitle} />;
};
