import {
  AudioAsset,
  audioChallengeAlmostThere,
  audioChallengeGoodJob,
  audioChallengeLuckyShot,
  audioChallengeWarmUp,
  audioOutroFailed,
  audioOutroSuccess,
} from '../../../../assets/audio';

export type Challenge = {
  name: string;
  description: string;
  initialValue: string;
  title: string;
  correctAudio: AudioAsset;
};

export const challenges: Challenge[] = [
  // Format a string of names
  {
    description: `Given: an array containing hashes of names

Return: a string formatted as a list of names separated by commas except for the last two names, which should be separated by an ampersand.
    
Example:
<pre><code class="language-js">list([ {name: 'Bart'}, {name: 'Lisa'}, {name: 'Maggie'} ])
// returns 'Bart, Lisa & Maggie'

list([ {name: 'Bart'}, {name: 'Lisa'} ])
// returns 'Bart & Lisa'

list([ {name: 'Bart'} ])
// returns 'Bart'

list([])
// returns ''</code></pre>
Note: all the hashes are pre-validated and will only contain A-Z, a-z, '-' and '.'.
`,
    initialValue: `function list(names) {
  //your code here
}`,
    name: 'formatAStringOfNames',
    title: 'Format a string of names',
    correctAudio: audioChallengeWarmUp,
  },

  //First non repeating letter
  {
    description: `Write a function named <code>firstNonRepeatingLetter</code> that takes a string input, and returns the first character that is not repeated anywhere in the string.

For example, if given the input <code>'stress'</code>, the function should return <code>'t'</code>, since the letter t only occurs once in the string, and occurs first in the string.
    
If a string contains all repeating characters, it should return an empty string (<code>""</code>)`,

    initialValue: `function firstNonRepeatingLetter(s) {
  // Add your code here
}`,
    name: 'firstNonRepeatingLetter',
    title: 'First non repeating letter',
    correctAudio: audioChallengeLuckyShot,
  },

  // Detect Pangram
  {
    description: `A pangram is a sentence that contains every single letter of the alphabet at least once. For example, the sentence "The quick brown fox jumps over the lazy dog" is a pangram, because it uses the letters A-Z at least once (case is irrelevant).

Given a string, detect whether or not it is a pangram. Return True if it is, False if not. Ignore numbers and punctuation.`,
    initialValue: `function isPangram(s) {
  // Add your code here
}`,
    name: 'detectPangram',
    title: 'Detect Pangram',
    correctAudio: audioChallengeGoodJob,
  },

  // Turtoise racing
  {
    description: `Two tortoises named <b>A</b> and <b>B</b> must run a race. <b>A</b> starts with an average speed of <code>720 feet per hour</code>. Young <b>B</b> knows she runs faster than <b>A</b>, and furthermore has not finished her cabbage.

When she starts, at last, she can see that <b>A</b> has a <code>70 feet lead</code> but <b>B</b>'s speed is <code>850 feet per hour</code>. How long will it take <b>B</b> to catch <b>A</b>?

More generally: given two speeds <code>v1</code> (<b>A</b>'s speed, integer > 0) and <code>v2</code> (<b>B</b>'s speed, integer > 0) and a lead <code>g</code> (integer > 0) how long will it take <b>B</b> to catch <b>A</b>?

The result will be an array <code>[hour, min, sec]</code> which is the time needed in hours, minutes and seconds (round down to the nearest second) or a string in some languages.

If <code>v1 >= v2</code> then return null.

Example:
<pre><code class="language-js">race(720, 850, 70)
// returns [0, 32, 18]

race(80, 91, 37)
// returns [3, 21, 49]"</code></pre>
`,
    initialValue: `function race(v1, v2, g) {
  // Add your code here     
}`,
    name: 'turtoiseRacing',
    title: 'Turtoise racing',
    correctAudio: audioOutroSuccess,
  },
];
