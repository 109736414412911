import React, { useReducer, Dispatch } from 'react';
import { Action, ActionWithPayload } from '../interfaces/Action';
import {
  START_GAME,
  AUTHENTICATE,
  PREPARE_NEXT_VIDEO,
  NEXT_SCENE,
  VIDEO_END,
  OPEN_GAME,
  TICK,
  START_GAME_TIME,
  SET_GAME_DATA,
  END_GAME,
  STOP_GAME_TIME,
} from './constants/gameActions';
import {
  Scene,
  scenes,
  normalScenes,
  completedScenes,
  failedScenes,
} from './library/scenes';
import { VideoAsset } from '../interfaces/VideoAsset';
import { GAME_MAX_TIME } from '../constants/game.constants';

export type GameState = {
  gameOpened: boolean;
  authenticated: boolean;
  gameStarted: boolean;
  gameFinished: boolean;
  gameTimeStarted: boolean;
  gameStartTime: number;
  gameEndTime: number;
  gameTimeRemaining: number;
  gameCompleted: boolean;
  playerName: string;
  gameId: string;
  scenes: Scene[];
  currentScene: Scene | null;
  currentSceneIndex: number;
  currentVideo: VideoAsset | null;
  transitionAfterVideoEnd: boolean;
  nextVideo: VideoAsset | null;
  previousSceneResult: boolean;
};

const endGameState = (state: GameState, result: boolean): GameState => {
  const newScenes = result ? completedScenes : failedScenes;
  return state.gameFinished
    ? state
    : {
        ...state,
        scenes: newScenes,
        currentScene: newScenes[0],
        currentVideo: newScenes[0].video
          ? newScenes[0].video
          : state.currentVideo,
        gameFinished: true,
        gameEndTime: Date.now(),
        gameTimeStarted: false,
        gameCompleted: result,
        currentSceneIndex: 0,
      };
};

const nextSceneState = (
  state: GameState,
  currentResult: boolean
): GameState => {
  const nextSceneIndex = state.currentSceneIndex + 1;
  const nextScene = state.scenes[nextSceneIndex];

  return nextSceneIndex < state.scenes.length
    ? {
        ...state,
        currentSceneIndex: nextSceneIndex,
        currentScene: nextScene,
        currentVideo: nextScene.video ? nextScene.video : state.currentVideo,
      }
    : endGameState(state, currentResult);
};

const reducer = (state: GameState, action: Action): GameState => {
  switch (action.type) {
    case OPEN_GAME:
      return {
        ...state,
        gameOpened: true,
        currentScene: scenes[0],
        currentVideo: scenes[0].video || null,
      };

    case START_GAME:
      return {
        ...state,
        gameStarted: true,
        transitionAfterVideoEnd: true,
      };

    case END_GAME:
      const endGameAction = action as ActionWithPayload<boolean>;
      return endGameState(state, endGameAction.payload);

    case START_GAME_TIME:
      return {
        ...state,
        gameTimeStarted: true,
        gameStartTime: Date.now(),
      };

    case STOP_GAME_TIME:
      const stopGameTimeAction = action as ActionWithPayload<number>;

      return {
        ...state,
        gameTimeStarted: false,
        gameTimeRemaining: stopGameTimeAction.payload,
      };

    case PREPARE_NEXT_VIDEO:
      const preparenextVideoAction = action as Action<VideoAsset>;
      return {
        ...state,
        nextVideo: preparenextVideoAction.payload || null,
      };

    case NEXT_SCENE:
      const nextSceneAction = action as ActionWithPayload<{
        currentResult: boolean;
        waitForVideoEnd: boolean;
      }>;

      return {
        ...state,
        ...(nextSceneAction.payload.waitForVideoEnd
          ? { transitionAfterVideoEnd: true }
          : nextSceneState(state, nextSceneAction.payload.currentResult)),
        previousSceneResult:
          typeof nextSceneAction.payload.currentResult === 'undefined'
            ? true
            : nextSceneAction.payload.currentResult,
      };

    case VIDEO_END:
      return state.transitionAfterVideoEnd
        ? {
            ...state,
            ...nextSceneState(state, state.previousSceneResult),
            transitionAfterVideoEnd: false,
          }
        : state;

    case SET_GAME_DATA:
      const setPlayerNameAction = action as ActionWithPayload<{
        playerName: string;
        gameId: string;
      }>;
      const { playerName, gameId } = setPlayerNameAction.payload;

      return {
        ...state,
        playerName,
        gameId,
      };

    case TICK:
      return {
        ...state,
        gameTimeRemaining: Math.max(
          GAME_MAX_TIME - (Date.now() - state.gameStartTime) / 1000,
          0
        ),
      };
  }

  return state;
};

const initialState: GameState = {
  gameStartTime: 0,
  gameEndTime: 0,
  authenticated: false,
  playerName: '',
  gameId: '',
  gameOpened: false,
  gameStarted: false,
  gameFinished: false,
  gameTimeStarted: false,
  gameCompleted: false,
  gameTimeRemaining: GAME_MAX_TIME,
  scenes: normalScenes,
  currentScene: null,
  currentSceneIndex: 0,
  currentVideo: null,
  nextVideo: null,
  transitionAfterVideoEnd: false,
  previousSceneResult: true,
};

export const useGameStore = (): [GameState, Dispatch<Action>] => {
  const [state, dispatch] = useReducer<React.Reducer<GameState, Action>>(
    reducer,
    initialState
  );

  return [state, dispatch];
};
