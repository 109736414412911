import React from 'react';
import {
  SCENE_LOBBY,
  SCENE_ENTER_ROOM,
  SCENE_ROOM_INTRO,
  SCENE_OUTRO_FAILED,
  SCENE_GAME_RESULT,
  SCENE_OUTRO_SUCCESS,
  SCENE_OUTRO_SUCCESS_INTRO,
  SCENE_LOBBY_INTRO,
} from '../../constants/scene.constants';
import { VideoAsset } from '../../interfaces/VideoAsset';
import {
  videoLobbyLoop,
  videoEnterRoom,
  videoRoomLoop,
  videoRoomFailed,
  videoLobbyIntro,
  videoRoomSuccess,
} from '../../assets/video';
import { LobbyScene } from '../../components/Game/scenes/LobbyScene';
import { RoomIntroScene } from '../../components/Game/scenes/RoomIntroScene';
import { OutroFailedScene } from '../../components/Game/scenes/OutroFailedScene';
import { GameResultScene } from '../../components/Game/scenes/GameResultScene';
import { OutroSuccessScene } from '../../components/Game/scenes/OutroSucessScene';
import { DummyScene } from '../../components/Game/scenes/DummyScene';
import { challenges } from '../../components/Game/scenes/library/challenges';
import { ChallengeScene } from '../../components/Game/scenes/ChallengeScene';

export type Scene = {
  name: string;
  scene: React.ReactElement;
  video?: VideoAsset;
};

export const normalScenes: Scene[] = [
  {
    name: SCENE_LOBBY_INTRO,
    video: videoLobbyIntro,
    scene: <DummyScene />,
  },
  {
    name: SCENE_LOBBY,
    video: videoLobbyLoop,
    scene: <LobbyScene />,
  },
  {
    name: SCENE_ENTER_ROOM,
    video: videoEnterRoom,
    scene: <DummyScene />,
  },
  {
    name: SCENE_ROOM_INTRO,
    scene: <RoomIntroScene />,
    video: videoRoomLoop,
  },
  ...challenges.map((challenge, index) => ({
    name: `challenge-${index + 1}`,
    scene: (
      <ChallengeScene
        key={challenge.name}
        challengeNumber={index + 1}
        {...challenge}
      />
    ),
  })),
];

export const failedScenes: Scene[] = [
  {
    name: SCENE_OUTRO_FAILED,
    video: videoRoomFailed,
    scene: <OutroFailedScene />,
  },
  {
    name: SCENE_GAME_RESULT,
    scene: <GameResultScene />,
  },
];

export const completedScenes: Scene[] = [
  {
    name: SCENE_OUTRO_SUCCESS,
    video: videoRoomSuccess,
    scene: <DummyScene />,
  },
  {
    name: SCENE_GAME_RESULT,
    scene: <GameResultScene />,
  },
];

export const scenes: Scene[] = [
  ...normalScenes,
  ...failedScenes,
  ...completedScenes,
];

export const numNormalScenes = normalScenes.length;
