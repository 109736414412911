import React, { useEffect } from 'react';
import { useGameActions } from '../../../hooks/useGameActions';
import { useGameState } from '../../../hooks/useGameState';

export const DummyScene: React.FC = () => {
  const { nextScene } = useGameActions();
  const { transitionAfterVideoEnd } = useGameState();

  useEffect(() => {
    if (!transitionAfterVideoEnd) {
      nextScene(true, true);
    }
  }, [nextScene, transitionAfterVideoEnd]);

  return null;
};
