import React from 'react';
import { Controlled } from 'react-codemirror2';
//@ts-ignore
import { JSHINT } from 'jshint';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/jsx/jsx';
import 'codemirror/addon/hint/javascript-hint';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/javascript-lint';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/darcula.css';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/addon/hint/show-hint.css';

import './CodeEditor.css';

//@ts-ignore
window.JSHINT = JSHINT;

export type CodeEditorProps = {
  value: string;
  onChange: (value: string) => void;
};

export const CodeEditor: React.FC<CodeEditorProps> = ({ onChange, value }) => {
  return (
    <Controlled
      className={'editor'}
      value={value}
      onBeforeChange={(options, event, value) => onChange(value)}
      options={{
        mode: 'jsx',
        theme: 'darcula',
        lineNumbers: true,
        autoRefresh: true,
        lineWrapping: false,
        styleActiveLine: true,
        tabSize: 2,
        lint: {
          esversion: 9,
        },
        gutters: ['CodeMirror-lint-markers'],
        extraKeys: { 'Ctrl-Space': 'autocomplete' },
      }}
    />
  );
};
