import React from 'react';
import { useGameState } from '../../../hooks/useGameState';
import classes from './TimeRemaining.module.css';
import { Time } from '../Time/Time';

export type TimeRemainingProps = {
  className?: string;
};

export const TimeRemaining: React.FC<TimeRemainingProps> = ({ className }) => {
  const { gameTimeStarted, gameTimeRemaining } = useGameState();

  return gameTimeStarted ? (
    <div className={`${className} ${classes.wrapper}`}>
      <Time time={gameTimeRemaining} />
    </div>
  ) : null;
};
