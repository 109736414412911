import React from 'react';
import { getMinutesSeconds } from '../../../helper/time';
import classes from './Time.module.css';

export type TimeProps = {
  time: number;
};

export const Time: React.FC<TimeProps> = ({ time }) => {
  const [minutes, seconds] = getMinutesSeconds(time);

  return <span className={classes.wrapper}>{`${minutes}:${seconds}`}</span>;
};
