import React from 'react';
import { FacebookIcon } from '../icons/Facebook';
import { LinkedInIcon } from '../icons/LinkedIn';
import { TwitterIcon } from '../icons/Twitter';
import classes from './SocialShare.module.css';

export type SocialShareProps = {
  onFacebook: () => void;
  onTwitter: () => void;
  onLinkedIn: () => void;
};

const SocialButton: React.FC<{ onClick: () => void }> = ({
  onClick,
  children,
}) => (
  <button className={classes.button} onClick={onClick}>
    {children}
  </button>
);

export const SocialShare: React.FC<SocialShareProps> = ({
  onFacebook,
  onLinkedIn,
  onTwitter,
}) => {
  return (
    <div className={classes.wrapper}>
      <SocialButton onClick={onFacebook}>
        <FacebookIcon />
      </SocialButton>
      <SocialButton onClick={onTwitter}>
        <TwitterIcon />
      </SocialButton>
      <SocialButton onClick={onLinkedIn}>
        <LinkedInIcon />
      </SocialButton>
    </div>
  );
};
