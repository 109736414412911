import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import classes from './ContentOverlay.module.css';

export type ContentOverlayProps = {
  large?: boolean;
};

export const ContentOverlay: React.FC<ContentOverlayProps> = ({
  children,
  large = false,
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    window.setTimeout(() => {
      setMounted(true);
    }, 100);
  }, []);

  const heightClass = mounted
    ? large
      ? classes.large
      : classes.normal
    : undefined;

  return (
    <div className={cx(classes.wrapper, heightClass)}>
      <div className={classes.content}>{children}</div>
    </div>
  );
};
