import React, { SyntheticEvent } from 'react';
import { VideoAsset } from '../../interfaces/VideoAsset';
import { scenes } from '../../hooks/library/scenes';
import { useGameState } from '../../hooks/useGameState';
import { useGameActions } from '../../hooks/useGameActions';

export type VideoPlayerProps = {};

export const VideoPlayer: React.FC<VideoPlayerProps> = () => {
  const { currentVideo, transitionAfterVideoEnd } = useGameState();
  const { videoEnd } = useGameActions();
  const videos = scenes
    .filter((scene) => scene.video)
    .map((scene) => scene.video)
    .reverse() as VideoAsset[];

  const handleTimeUpdate = (videoAsset: VideoAsset) => (
    event: SyntheticEvent<HTMLVideoElement>
  ) => {
    const buffer = 0.6;
    const videoElement = event.currentTarget;

    if (
      currentVideo?.asset === videoAsset.asset &&
      videoElement.currentTime >= videoElement.duration - buffer
    ) {
      if (transitionAfterVideoEnd) {
        videoEnd();
      } else if (videoAsset?.loop) {
        videoElement.currentTime = 0.1;
        videoElement.play();
      }
    }
  };

  return (
    <div>
      {videos.map((videoAsset) => {
        const isCurrentVideo =
          currentVideo && currentVideo.asset === videoAsset.asset;

        return (
          <video
            width="100%"
            height="100%"
            key={videoAsset.name}
            src={videoAsset.asset}
            loop={false}
            autoPlay={false}
            preload={videoAsset.asset}
            onTimeUpdate={handleTimeUpdate(videoAsset)}
            ref={(ref) => {
              if (isCurrentVideo && ref) {
                const videoRef = ref as HTMLVideoElement;

                if (videoRef.currentTime === 0) {
                  videoRef.play();
                }
              }
            }}
            muted={false}
            style={{
              zIndex: isCurrentVideo ? 1 : 0,
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              objectFit: 'cover',
              overflow: 'hidden',
              opacity: isCurrentVideo ? 1 : 0,
            }}
          />
        );
      })}
    </div>
  );
};
