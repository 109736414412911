import React from 'react';
import { useAudio } from '../../../hooks/useAudio';
import { audioOutroFailed } from '../../../assets/audio';
import { useGameActions } from '../../../hooks/useGameActions';
import { Subtitle } from '../Subtitle/Subtitle';

export const OutroFailedScene: React.FC = () => {
  const { nextScene } = useGameActions();
  const { subtitle } = useAudio(audioOutroFailed, {
    autoPlay: true,
    delayStart: 500,
    onComplete: () => nextScene(false, true),
  });

  return <Subtitle message={subtitle} />;
};
