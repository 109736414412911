import React from 'react';
import classes from './TextInput.module.css';

export type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const TextInput: React.FC<InputProps> = (props) => {
  return <input {...props} className={classes.wrapper} type="text" />;
};
