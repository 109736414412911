import * as React from 'react';
import { GameState } from '../hooks/useGameStore';
import { Action } from '../interfaces/Action';

interface GameContext {
  state: GameState;
  dispatch: React.Dispatch<Action>;
}

export const gameContext = React.createContext<GameContext>({} as GameContext);

export const GameProvider = gameContext.Provider;
export const GameConsumer = gameContext.Consumer;
