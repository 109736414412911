import React from 'react';
import { useGameState } from '../../../hooks/useGameState';
import { ContentOverlay } from '../ContentOverlay/ContentOverlay';
import { Title } from '../../Title/Title';
import { Time } from '../Time/Time';
import { GAME_MAX_TIME } from '../../../constants/game.constants';
import classes from './GameResultScene.module.css';
import { getMinutesSeconds } from '../../../helper/time';
import { SocialShare } from '../../SocialShare/SocialShare';

export const GameResultScene: React.FC = () => {
  const {
    gameCompleted,
    gameTimeRemaining,
    playerName,
    gameId,
  } = useGameState();
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  const shareUrl = `${baseUrl}/share/${gameId}`;
  const [minutes, seconds] = getMinutesSeconds(
    GAME_MAX_TIME - gameTimeRemaining
  );

  function openShareWindow(url: string) {
    const width = 400;
    const height = 600;
    const windowWidth = window.outerWidth - width;
    const windowHeight = window.outerHeight - height;

    const windowLeft = Math.round(window.screenX + windowWidth / 2);
    const windowTop = Math.round(window.screenY + windowHeight / 2.5);

    window.open(
      url,
      'Share',
      `width=${width},height=${height},left=${windowLeft},top=${windowTop},toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0`
    );
  }

  function shareOnFB() {
    openShareWindow(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`);
  }

  function shareOntwitter() {
    openShareWindow(
      `https://twitter.com/intent/tweet?url=${baseUrl}&via=codecapi&text=I've finished this game in ${minutes}:${seconds}. Think you can beat me?`
    );
  }

  function shareOnLinkedIn() {
    openShareWindow(
      `https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}`
    );
  }

  return (
    <ContentOverlay>
      {gameCompleted ? (
        <>
          <Title>You completed the challenges!</Title>
          <p className={classes.paragraph}>
            Congratulations {playerName}, you completed all the challenges in{' '}
            <Time time={GAME_MAX_TIME - gameTimeRemaining} />
          </p>
          <p className={classes.paragraph}>Share your score!</p>
          <SocialShare
            onFacebook={shareOnFB}
            onLinkedIn={shareOnLinkedIn}
            onTwitter={shareOntwitter}
          />
        </>
      ) : (
        <Title>You failed</Title>
      )}
    </ContentOverlay>
  );
};
