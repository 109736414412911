import { useState, useEffect, useCallback } from 'react';
import { AudioAsset } from '../assets/audio';

export type UseAudioOptions = {
  onComplete?: () => void;
  onStart?: () => void;
  delayStart?: number;
  autoPlay?: boolean;
};

export const useAudio = (asset: AudioAsset, options: UseAudioOptions) => {
  const noop = () => {};
  const {
    onComplete = noop,
    onStart = noop,
    delayStart = 0,
    autoPlay = true,
  } = options;
  const [subtitleIndex, setSubtitleIndex] = useState(0);
  const [message, setMessage] = useState('');
  const [sound, setSound] = useState(new Audio(asset.asset));
  const [playing, setPlaying] = useState(false);
  const [playStarted, setPlayStarted] = useState(false);
  const subtitle = asset.subtitles[subtitleIndex];

  const playCallback = useCallback(() => {
    sound.onplaying = () => {
      setPlaying(true);
      setPlayStarted(true);
      setSubtitleIndex(0);
      onStart();
    };
    sound.onended = () => {
      setPlaying(false);
    };
    sound.currentTime = 0;
    sound.play();
  }, [sound]);

  useEffect(() => {
    setSound(new Audio(asset.asset));
    setSubtitleIndex(0);
    setMessage(asset.subtitles[0]?.message || '');
  }, [asset]);

  useEffect(() => {
    let timeoutId: number;

    if (autoPlay) {
      timeoutId = window.setTimeout(playCallback, delayStart);
    }

    return () => window.clearTimeout(timeoutId);
  }, [playCallback, delayStart, autoPlay]);

  useEffect(() => {
    if (playStarted) {
      const nextSubtitleIndex = subtitleIndex + 1;
      const subtitleLength = asset.subtitles.length;
      const callback =
        nextSubtitleIndex < subtitleLength
          ? () => setSubtitleIndex(nextSubtitleIndex)
          : () => setMessage('');
      const timeoutId = window.setTimeout(callback, subtitle.timeOnScreen);

      return () => window.clearTimeout(timeoutId);
    }
  }, [subtitleIndex, playStarted]);

  useEffect(() => {
    setMessage(asset.subtitles[subtitleIndex]?.message || '');
  }, [subtitleIndex]);

  useEffect(() => {
    if (playStarted && !playing && message === '') {
      setPlayStarted(false);
      onComplete();
    }
  }, [playStarted, playing, message, onComplete]);

  return {
    play: playCallback,
    subtitle: message,
  };
};
