import React from 'react';
import { useGameActions } from '../../../hooks/useGameActions';
import { Button } from '../../Button/Button';
import classes from './JoinGame.module.css';

export const JoinGame: React.FC = () => {
  const { openGame } = useGameActions();

  return (
    <div className={classes.wrapper}>
      <img className={classes.logo} src="/assets/logo-game.png" />
      <p className={classes.text}>
        This game will test your JavaScript skills to the max. Do you have what
        it takes to program your way out? Play now and finish all four
        challenges in under one hour!
      </p>

      <Button onClick={openGame}>Start game</Button>
    </div>
  );
};
