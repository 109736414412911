type Subtitle = {
  message: string;
  timeOnScreen: number;
};

export type AudioAsset = {
  asset: string;
  delay?: number;
  subtitles: Subtitle[];
};

const basePath = 'https://storage.googleapis.com/codeout-audio/';

/*
Welcome. 

I am the Centralised Artificial Programmable Intelligence, short CAPI. You have entered a restricted access zone.

Activating safe harbour procedure. 

Data transfer initialized. 

Activating self destruction sequence in T-minus 60 minutes.

If you wish to deactivate the self destruction sequence, complete the following challenges.
*/
export const audioIntroRoom: AudioAsset = {
  asset: `${basePath}room-intro.mp3`,
  subtitles: [
    {
      message: 'Welcome',
      timeOnScreen: 1000,
    },
    {
      message:
        'I am the Centralised Artificial Programmable Intelligence, short CAPI.',
      timeOnScreen: 4500,
    },
    {
      message: 'You have entered a restricted access zone',
      timeOnScreen: 3000,
    },
    {
      message: 'Activating safe harbour procedure',
      timeOnScreen: 2800,
    },
    {
      message: 'Data transfer initialized',
      timeOnScreen: 2000,
    },
    {
      message: 'Activating self destruction sequence in T-minus 15 minutes',
      timeOnScreen: 4500,
    },
    {
      message: 'If you wish to deactivate the self destruction sequence',
      timeOnScreen: 4500,
    },
    {
      message: 'Complete the following challenges',
      timeOnScreen: 2500,
    },
  ],
};

export const audioChallengeWarmUp: AudioAsset = {
  asset: `${basePath}challenge-warm-up.mp3`,
  subtitles: [
    {
      message: `Your answer is correct`,
      timeOnScreen: 1500,
    },
    {
      message: `Don't get excited yet, this was just the warm up round`,
      timeOnScreen: 4500,
    },
  ],
};

export const audioChallengeLuckyShot: AudioAsset = {
  asset: `${basePath}challenge-lucky-shot.mp3`,
  subtitles: [
    {
      message: `Lucky shot`,
      timeOnScreen: 1300,
    },
    {
      message: `Let's see how you handle the next one`,
      timeOnScreen: 4500,
    },
  ],
};

export const audioChallengeGoodJob: AudioAsset = {
  asset: `${basePath}challenge-good-job.mp3`,
  subtitles: [
    {
      message: `Good job`,
      timeOnScreen: 1300,
    },
    {
      message: `Maybe you've got some skills after all`,
      timeOnScreen: 2500,
    },
  ],
};

export const audioChallengeAlmostThere: AudioAsset = {
  asset: `${basePath}challenge-almost-there.mp3`,
  subtitles: [
    {
      message: `Almost there, keep going!`,
      timeOnScreen: 2000,
    },
  ],
};

export const audioOutroSuccess: AudioAsset = {
  asset: `${basePath}outro-success.mp3`,
  subtitles: [
    {
      message: 'Self destruction sequence terminated',
      timeOnScreen: 2500,
    },
    { message: 'Welcome, master', timeOnScreen: 2000 },
  ],
};

export const audioOutroFailed: AudioAsset = {
  asset: `${basePath}outro-failed.mp3`,
  subtitles: [
    {
      message: 'You failed, goodbye',
      timeOnScreen: 2000,
    },
  ],
};
