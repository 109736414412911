import React from 'react';
import './App.css';
import { useGameStore } from './hooks/useGameStore';
import { GameProvider } from './context/gameContext';
import { Game } from './components/Game/Game';

function App() {
  const [state, dispatch] = useGameStore();

  return (
    <div className="App">
      <GameProvider value={{ state, dispatch }}>
        <Game />
      </GameProvider>
    </div>
  );
}

export default App;
