import React from 'react';

export const FacebookIcon = () => (
  <svg height="512px" version="1.1" viewBox="0 0 512 512" width="512px">
    <g id="facebook__x2C__social__x2C__media_x2C__icon_x2C_">
      <path
        d="M434.478,16H77.519C43.718,16,16,43.719,16,77.521v356.957   C16,468.282,43.718,496,77.519,496h356.959C468.281,496,496,468.282,496,434.479V77.521C496,43.719,468.281,16,434.478,16z    M327.203,230.875L324.906,256H277c0,72,0,160,0,160h-64c0,0,0-87,0-160h-32v-25.368V192h32v-29.038   c0-26.212,12.524-67.166,67.461-67.166L330,96.021V151c0,0-30.163,0-36.003,0c-5.848,0-13.997,2.812-13.997,15.284V192h50.743   L327.203,230.875z"
        style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#1A1A1A;' }}
      />
    </g>
    <g id="Layer_1" />
  </svg>
);
