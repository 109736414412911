export const SCENE_LOBBY_INTRO = 'scene-lobby-intro';
export const SCENE_LOBBY = 'scene-lobby';
export const SCENE_AUTHENTICATE = 'scene-authenticate';
export const SCENE_ENTER_ROOM = 'scene-enter-room';
export const SCENE_ROOM_INTRO = 'scene-room-intro';
export const SCENE_CHALLENGE_1 = 'scene-challenge-1';
export const SCENE_CHALLENGE_2 = 'scene-challenge-2';
export const SCENE_OUTRO_SUCCESS_INTRO = 'scene-outro-sucess-intro';
export const SCENE_OUTRO_SUCCESS = 'scene-outro-sucess';
export const SCENE_OUTRO_FAILED = 'scene-outro-failed';
export const SCENE_GAME_RESULT = 'scene-game-result';
