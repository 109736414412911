import React from 'react';
import classes from './Footer.module.css';

export const Footer: React.FC = () => {
  return (
    <div className={classes.wrapper}>
      <p>
        <a
          href="https://codecapi.nl/nl/vacature/javascript-developer/"
          target="_blank"
        >
          Join our team!
        </a>
      </p>
      <p>-</p>
      <p>
        <a href="https://codecapi.nl" target="_blank">
          [code]capi
        </a>
      </p>
      <p>-</p>
      <p>
        Challenges courtesy of{' '}
        <a href="https://www.codewars.com/" target="_blank">
          CodeWars
        </a>
      </p>
    </div>
  );
};
