import React from 'react';
import classnames from 'classnames';
import classes from './Button.module.css';

export type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  icon?: React.ReactElement;
  disabled?: boolean;
};

export const Button: React.FC<ButtonProps> = ({
  children,
  icon,
  disabled,
  onClick,
  className = '',
  ...props
}) => {
  return (
    <button
      {...props}
      onClick={(e) => {
        if (!disabled && onClick) {
          onClick(e);
        }
      }}
      type="button"
      className={classnames(
        classes.button,
        className,
        disabled && classes.disabled
      )}
    >
      {icon && <span className={classes.icon}>{icon}</span>}
      <span className={`${classes.text} ${icon ? classes.hasIcon : ''}`}>
        {children}
      </span>
    </button>
  );
};
