import React, { useEffect } from 'react';
import { useGameActions } from '../../hooks/useGameActions';
import { useGameState } from '../../hooks/useGameState';
import { VideoPlayer } from '../VideoPlayer/VideoPlayer';
import classes from './Game.module.css';
import { TimeRemaining } from './TimeRemaining/TimeRemaining';
import { JoinGame } from './JoinGame/JoinGame';
import { SoundTestBoard } from './SoundTestBoard/SoundTestBoard';
import { GoFullscreen } from './GoFullscreen/GoFullscreen';
import { Footer } from './Footer/Footer';

export const Game = () => {
  const { tick, endGame } = useGameActions();
  const {
    currentScene,
    gameOpened,
    gameTimeStarted,
    transitionAfterVideoEnd,
    gameTimeRemaining,
    gameFinished,
    gameStarted,
  } = useGameState();
  const scene = currentScene?.scene;

  useEffect(() => {
    let intervalId: number;
    if (gameTimeStarted && !gameFinished) {
      intervalId = window.setInterval(tick, 200);
    }

    return () => clearInterval(intervalId);
  }, [gameTimeStarted, gameFinished, tick]);

  useEffect(() => {
    if (gameTimeRemaining <= 0) {
      endGame(false);
    }
  }, [gameTimeRemaining, endGame]);

  useEffect(() => {
    const warnOnReload = (e: Event) => {
      e.preventDefault();
      //@ts-ignore
      e.returnValue = 'Are you sure?';
    };

    if (gameStarted) {
      window.addEventListener('beforeunload', warnOnReload);
    }

    return () => window.removeEventListener('beforeunload', warnOnReload);
  }, [gameStarted]);

  return (
    <>
      <div className={classes.gameContainer}>
        <div className={classes.aspectRatioContainer}>
          <div className={classes.contentWrapper}>
            {gameOpened ? (
              <>
                <a
                  href="https://codecapi.nl"
                  target="_blank"
                  className={classes.image}
                >
                  <img
                    alt="[code]capi logo"
                    width={100}
                    src="/assets/logo.png"
                  />
                </a>
                <VideoPlayer />
                <div className={classes.sceneContainer}>
                  <TimeRemaining className={classes.timeContainer} />
                  {scene && !transitionAfterVideoEnd && scene}
                </div>
                <GoFullscreen />
              </>
            ) : (
              <JoinGame />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
