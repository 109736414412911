import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useGameActions } from '../../../hooks/useGameActions';
import { Title } from '../../Title/Title';
import { Button } from '../../Button/Button';
import classes from './LobbyScene.module.css';
import { ContentOverlay } from '../ContentOverlay/ContentOverlay';
import { LinkedInIcon } from '../../icons/LinkedIn';
import { TextInput } from '../../TextInput/TextInput';

const isEmail = (email: string) =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

export const LobbyScene: React.FC = () => {
  const { setGameData, startGame } = useGameActions();
  const [oldVerification, setOldVerification] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleConnect = () => {
    const width = 400;
    const height = 600;
    const windowWidth = window.outerWidth - width;
    const windowHeight = window.outerHeight - height;

    const windowLeft = Math.round(window.screenX + windowWidth / 2);
    const windowTop = Math.round(window.screenY + windowHeight / 2.5);
    const url = '/auth/linkedin';

    window.open(
      url,
      'LogIn',
      `width=${width},height=${height},left=${windowLeft},top=${windowTop},toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0`
    );
  };

  useEffect(() => {
    const handleMessageEvent = (event: any) => {
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'popup-done') {
          const { gameId, playerName } = data;
          setGameData(playerName, gameId);
          startGame();
        }
      } catch (e) {}
    };

    window.addEventListener('message', handleMessageEvent);

    return () => window.removeEventListener('message', handleMessageEvent);
  });

  const isValid = isEmail(email) && name.trim() !== '';

  const handleSubmit = async () => {
    if (isValid) {
      const { data } = await axios.post(
        `${window.location.protocol}//${window.location.hostname}/api/create`,
        {
          username: name,
          email,
        }
      );

      setGameData(name, data.gameId);
      startGame();
    }
  };

  return (
    <ContentOverlay>
      {oldVerification === false ? (
        <>
          <Title>Connect to play</Title>
          <p className={`${classes.text} text`}>
            In order to play this game simply connect using your LinkedIn
            account!
          </p>
          <Button onClick={handleConnect} icon={<LinkedInIcon />}>
            connect
          </Button>

          <a
            href="#"
            className={classes.link}
            onClick={(event) => {
              event.preventDefault();
              setOldVerification(true);
            }}
          >
            I rather not
          </a>
        </>
      ) : (
        <>
          <Title>Fill out to play</Title>
          <p className={`${classes.text} text`}>
            In order to play this game simply fill out the form.
          </p>
          <div className={classes.form}>
            <TextInput
              value={name}
              placeholder="Username"
              onChange={(event) => setName(event.target.value)}
            />
            <TextInput
              value={email}
              placeholder="Email"
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>

          <Button disabled={!isValid} onClick={handleSubmit}>
            Submit
          </Button>
        </>
      )}
    </ContentOverlay>
  );
};
