import React, { useState, useMemo, useEffect } from 'react';
import axios from 'axios';
import classes from './ChallengeScene.module.css';
import { CodeEditor } from '../../CodeEditor/CodeEditor';
import { Title } from '../../Title/Title';
import { ContentOverlay } from '../ContentOverlay/ContentOverlay';
import { Button } from '../../Button/Button';
import { useGameState } from '../../../hooks/useGameState';
import { formatOutput } from './library/formatOutput';
import { formatDescription } from './library/formatDescription';
import { AudioAsset } from '../../../assets/audio';
import { useAudio } from '../../../hooks/useAudio';
import { Subtitle } from '../Subtitle/Subtitle';
import { useGameActions } from '../../../hooks/useGameActions';
import { GAME_MAX_TIME } from '../../../constants/game.constants';
import { challenges } from './library/challenges';

export type ChallengeSceneProps = {
  title: string;
  description: string;
  name: string;
  initialValue: string;
  challengeNumber: number;
  correctAudio: AudioAsset;
};

export const ChallengeScene: React.FC<ChallengeSceneProps> = ({
  title,
  description,
  initialValue,
  challengeNumber,
  name,
  correctAudio: onCorrectAudio,
}) => {
  const [code, setCode] = useState(initialValue);
  const [startTime] = useState(Date.now());
  const [correct, setCorrect] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { nextScene, stopGameTime } = useGameActions();
  const { play, subtitle } = useAudio(onCorrectAudio, {
    autoPlay: false,
    onComplete: () => nextScene(true, false),
  });
  const [consoleOutput, setConsoleOutput] = useState('');
  const [formattedDescription, setFormattedDescription] = useState('');
  const { gameId, gameStartTime, gameTimeRemaining } = useGameState();

  const execute = async (submit: boolean = false) => {
    setConsoleOutput(`<span class="${classes.bold}">Sending request...</span>`);
    const { data } = await axios.post(
      `${window.location.protocol}//${window.location.hostname}/api/execute`,
      {
        code,
        name,
        gameStartTime,
        submit,
        timeToComplete: Date.now() - startTime,
        completeTime: Date.now(),
        totalTimeSpend: GAME_MAX_TIME - gameTimeRemaining,
        gameId,
        challenge: challengeNumber,
        challengeTitle: title,
      }
    );
    const { expected, gotten, output, error, result } = data;
    const formattedConsole = formatOutput(
      { expected, gotten, output, error },
      classes
    );
    setConsoleOutput(formattedConsole);
    setCorrect(result);

    if (result && submit) {
      if (challengeNumber === challenges.length) {
        stopGameTime(gameTimeRemaining);
      }

      setSubmitted(true);
    }
  };

  const handleRun = async () => {
    await execute();
  };

  const handleSubmit = async () => {
    await execute(true);
  };

  useEffect(() => {
    if (submitted) {
      play();
    }
  }, [submitted, play]);

  useEffect(() => {
    async function format() {
      const formatted = await formatDescription(description);
      setFormattedDescription(formatted);
    }

    format();
  }, []);

  return submitted ? (
    <Subtitle message={subtitle} />
  ) : (
    <ContentOverlay large>
      <header className={classes.title}>
        <Title>{title}</Title>
      </header>
      <div className={classes.container}>
        <div className={classes.leftSide}>
          <div className={classes.editor}>
            <CodeEditor onChange={setCode} value={code} />
          </div>
        </div>
        <div className={classes.rightSide}>
          <div className={classes.description}>
            <h3 className={classes.label}>description</h3>
            <p
              dangerouslySetInnerHTML={{
                __html: formattedDescription,
              }}
            />
          </div>
          {consoleOutput && (
            <div className={classes.output}>
              <h3 className={classes.label}>output</h3>
              <div>
                <p
                  className="line"
                  dangerouslySetInnerHTML={{ __html: consoleOutput }}
                />
              </div>
            </div>
          )}
          <div className={classes.run}>
            <Button onClick={handleRun}>RUN</Button>
            {correct && <Button onClick={handleSubmit}>SUBMIT</Button>}
          </div>
        </div>
      </div>
    </ContentOverlay>
  );
};
