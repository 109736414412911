import {
  VIDEO_LOBBY_LOOP,
  VIDEO_ENTER_ROOM,
  VIDEO_ROOM_INTRO,
  VIDEO_ROOM_LOOP,
  VIDEO_ROOM_FAILED,
  VIDEO_LOBBY_INTRO,
  VIDEO_ROOM_SUCCESS,
} from '../constants/video.constants';
import { VideoAsset } from '../interfaces/VideoAsset';

const basePath = 'https://storage.googleapis.com/codeout-videos/';

export const videoLobbyIntro: VideoAsset = {
  name: VIDEO_LOBBY_INTRO,
  loop: false,
  asset: `${basePath}entry-intro.mp4`,
};

export const videoLobbyLoop: VideoAsset = {
  name: VIDEO_LOBBY_LOOP,
  loop: true,
  asset: `${basePath}entry-loop.mp4`,
};

export const videoEnterRoom: VideoAsset = {
  name: VIDEO_ENTER_ROOM,
  loop: false,
  asset: `${basePath}entry-to-room.mp4`,
};

export const videoRoomIntro: VideoAsset = {
  name: VIDEO_ROOM_INTRO,
  loop: false,
  asset: `${basePath}room-intro.mp4`,
};

export const videoRoomLoop: VideoAsset = {
  name: VIDEO_ROOM_LOOP,
  loop: true,
  asset: `${basePath}room-loop.mp4`,
};

export const videoRoomFailed: VideoAsset = {
  name: VIDEO_ROOM_FAILED,
  loop: false,
  asset: `${basePath}room-fail.mp4`,
};

export const videoRoomSuccess: VideoAsset = {
  name: VIDEO_ROOM_SUCCESS,
  loop: false,
  asset: `${basePath}room-success.mp4`,
};
