import React from 'react';
import cx from 'classnames';
import classes from './Title.module.css';

export type TitleProps = {
  className?: string;
  size?: 'normal' | 'large';
};

export const Title: React.FC<TitleProps> = ({
  children,
  className,
  size = 'normal',
}) => {
  return (
    <h1 className={cx(classes.wrapper, classes[size], className)}>
      {children}
    </h1>
  );
};
