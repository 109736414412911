export const OPEN_GAME = 'open_game';
export const START_GAME = 'start_game';
export const START_GAME_TIME = 'start_game_time';
export const STOP_GAME_TIME = 'stop_game_time';
export const END_GAME = 'end_game';
export const SET_GAME_DATA = 'set_game_data';
export const NEXT_SCENE = 'next_scene';
export const PREPARE_NEXT_VIDEO = 'prepare_next_video';
export const VIDEO_END = 'video_end';
export const TICK = 'tick';
export const AUTHENTICATE = 'authenticate';
