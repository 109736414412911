import React from 'react';
import classes from './Subtitle.module.css';

export type SubtitleProps = {
  message: string;
};

export const Subtitle: React.FC<SubtitleProps> = ({ message }) => {
  return <p className={classes.wrapper}>{message}</p>;
};
