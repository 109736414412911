import React from 'react';

export const TwitterIcon = () => (
  <svg
    data-name="Layer 1"
    id="Layer_1"
    viewBox="0 0 500 500"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title />
    <path d="M484.85,124.74a144.17,144.17,0,0,0-2.32-25.29c-1.94-10.19-4.67-20.12-9.55-29.33A101.84,101.84,0,0,0,453.39,44a97.14,97.14,0,0,0-42.76-24.4c-14.83-4-30-4.84-45.21-4.82a.46.46,0,0,1-.09-.23H134.59c0,.08,0,.16,0,.23-8.65.16-17.32.09-25.92,1.16A123.46,123.46,0,0,0,81,22.14,97.48,97.48,0,0,0,44.25,46.26,97.15,97.15,0,0,0,19.68,89.17c-3.94,14.72-4.8,29.73-4.82,44.85L14.7,365.69v0c.28,10.45.37,21,2.13,31.36,1.87,11,4.54,21.71,9.64,31.69A101.37,101.37,0,0,0,54.77,463a91.92,91.92,0,0,0,28.31,15.35c15.12,4.88,30.72,6.75,46.55,6.84,9.88.06,19.74.31,29.62.27,71.74-.3,143.49.52,215.23-.44a169.32,169.32,0,0,0,28.23-3A95.61,95.61,0,0,0,450,459c15.78-14.08,26.43-31.3,31.24-52.09,3.15-13.59,3.93-27.38,4.07-41.21v-2.76C485.3,361.86,484.89,127.84,484.85,124.74Zm-90,51.55s5.43,79.69-35.77,126.33c0,0-28.55,60.17-97.37,80.09,0,0-9.49,12.73-110.46,10.47,0,0-30.79.45-79.24-29,0,0,65.66,7.7,107.77-31.7,0,0-46.19-.45-67.47-48.9,0,0,22.64,1.81,32.6-2.26,0,0-58-14.94-57.51-73.81,0,0,20.83,10.87,31.7,8.15,0,0-53-38-23.55-97.35,0,0,70.18,78.79,152.14,74.26,0,0-12.68-54.34,48.9-82.41,0,0,53.88-15.4,76.52,17.21,0,0,34.87-3.17,46.19-16.3,0,0,4.08,10.87-29,39.39,0,0,22.64-1.36,37.58-10C428,140.52,427.5,154.56,394.9,176.29Z" />
  </svg>
);
