import { useContext } from 'react';
import {
  START_GAME,
  PREPARE_NEXT_VIDEO,
  NEXT_SCENE,
  VIDEO_END,
  SET_GAME_DATA,
  OPEN_GAME,
  TICK,
  START_GAME_TIME,
  END_GAME,
  AUTHENTICATE,
  STOP_GAME_TIME,
} from './constants/gameActions';
import { Action } from '../interfaces/Action';
import { VideoAsset } from '../interfaces/VideoAsset';
import { gameContext } from '../context/gameContext';

export type GameActions = {
  openGame: () => void;
  startGame: () => void;
  endGame: (result: boolean) => void;
  startGameTime: () => void;
  stopGameTime: (timeRemaining: number) => void;
  prepareNextVideo: (video: VideoAsset) => void;
  nextScene: (currentResult: boolean, waitForVideoEnd: boolean) => void;
  videoEnd: () => void;
  setGameData: (playerName: string, gameId: string) => void;
  tick: () => void;
  authenticate: () => void;
};

export const useGameActions = (): GameActions => {
  const { dispatch } = useContext(gameContext);
  const dispatchAndLog = (action: Action) => {
    // console.log('DISPATCH:', action.type, action.payload);
    dispatch(action);
  };

  const authenticate: GameActions['authenticate'] = () =>
    dispatchAndLog({ type: AUTHENTICATE });

  const openGame: GameActions['openGame'] = () =>
    dispatchAndLog({ type: OPEN_GAME });

  const startGame: GameActions['startGame'] = () =>
    dispatchAndLog({
      type: START_GAME,
    });

  const endGame: GameActions['endGame'] = (result: boolean) =>
    dispatchAndLog({
      type: END_GAME,
      payload: result,
    });

  const startGameTime: GameActions['startGameTime'] = () =>
    dispatchAndLog({
      type: START_GAME_TIME,
    });

  const stopGameTime: GameActions['stopGameTime'] = (timeRemaining) =>
    dispatchAndLog({
      type: STOP_GAME_TIME,
      payload: timeRemaining,
    });

  const prepareNextVideo: GameActions['prepareNextVideo'] = (payload) =>
    dispatchAndLog({
      type: PREPARE_NEXT_VIDEO,
      payload,
    });

  const nextScene: GameActions['nextScene'] = (
    currentResult,
    waitForVideoEnd
  ) =>
    dispatchAndLog({
      type: NEXT_SCENE,
      payload: { currentResult, waitForVideoEnd },
    });

  const videoEnd: GameActions['videoEnd'] = () =>
    dispatchAndLog({ type: VIDEO_END });

  const setGameData: GameActions['setGameData'] = (playerName, gameId) =>
    dispatchAndLog({ type: SET_GAME_DATA, payload: { playerName, gameId } });

  const tick: GameActions['tick'] = () => dispatch({ type: TICK });

  return {
    openGame,
    startGame,
    endGame,
    startGameTime,
    stopGameTime,
    prepareNextVideo,
    nextScene,
    videoEnd,
    setGameData,
    tick,
    authenticate,
  };
};
